export function getExtensionFile(fileType: string) {
  return `.${fileType.split("/").pop()}`;
}

export function isFileTooLarge(file: File, maxSize: number = 10000000): boolean {
  return file.size > maxSize;
}

export function isExtensionAccept(file: File, acceptExtensions?: string[]) {
  const extension = getExtensionFile(file.type);
  return acceptExtensions?.includes(extension);
}
export function convertToMB(valueInBytes: number): number {
  const megabyte = 1024 * 1024;
  const resultInMB = valueInBytes / megabyte;
  return Math.ceil(resultInMB);
}
